<template>
    <div class="home">
        <Header />
            <div class="root">
              <div class="content">
                <div class="newVersionItem">
                    <div class="newVersion_title">
                      <span class="newVersion_title_content">最新版本：</span>
                      <span v>{{updateLogsList.newVersion.version_name}}</span>
                    </div>
                    <div class="newVersion_content" style="white-space: pre-wrap;"  v-html="updateLogsList.newVersion.detail"></div>
                </div>
                <div class="history_version">
                  <div class="history_title" >
                    <img src="../assets/image/shizhong.png" alt="">
                    <span>歷史版本：</span>
                  </div>
                  <div class="history_content" v-for="(item, index) in updateLogsList.versionList" :key="index" >
                    <div class="history_version_name">{{item.version_name}}</div>
                    <div class="history_item"  style="white-space: pre-wrap;" v-html="item.detail"></div>
                  </div>
              </div>
            </div>
          </div>
        <FootNav  :activeIndex="active"/>
    </div>
  </template>
  
  <script>
  import Header from "../components/Header.vue";
  import FootNav from "../components/FootNav.vue";
  
    export default {
      components: {
          Header,
          FootNav
      },
      data() {
          return {
              active:2,
              updateLogsList:
              {
                  newVersion:{
                      ios_link:"",
                      android_link:""
                  },
              },
          };
      },
      created(){
          this.getUpdateLogs()
      },
      methods: {
          getUpdateLogs(){
              this.service.post('Home/updateLogs')
              .then((res) => {
                if(res.data.status == 1){
                  this.updateLogsList = res.data.data
                }
              }) 
          },
      },
    };
  
  </script>
  
<style scoped>
.content{
  margin-bottom: 70px;
}
.newVersionItem{
  padding: 23px 10px;
  border-bottom: 2px solid#FF813D;
}
.newVersion_title{
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 10px;
}
.newVersion_title_content{
  font-weight: bold;
}
.newVersion_content{
  color: #ffffff;
  font-size: 13px;
  line-height: 23px;
}
.history_title{
  font-size: 16px;
  line-height: 16px;
  height: 20px;
  margin-bottom: 15px;
  margin-top: 40px;
}
.history_title img{
  float: left;
  height: 16px;
  width: 16px;
  color: #ffffff;
}
.history_title span{
  float: left;
  color: #ffffff;
  font-weight: bold;
}
.history_content{
  margin-bottom: 40px;
}
.history_version{
  padding: 0px 10px;
}
.history_version_name{
  color: #ffffff;
  width: 100%;
  height: 16px;
  margin-bottom: 15px;
}
.history_item{
  color: #ffffff;
  font-size: 13px;
  line-height: 23px;
}
</style>
  